// import '../molecules/reaction1_1.mol'

// export const BASEURL = '/molToJson/'
export const BASEURL = "/mol/"

// 配置文件名
// export const molUrlArr = [
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "Sisunatovir",
//   "Disoxaril",
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "Sisunatovir",
//   "Disoxaril",
//   "Sisunatovir",
//   "Disoxaril",
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "Sisunatovir",
//   "Disoxaril",
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "Sisunatovir",
//   "Disoxaril",
//   "VicrivirocMaleate",
//   "Pleconaril",
//   "Presatovir",
//   "Desaspidin",
//   "Sisunatovir",
//   "Disoxaril",
// ]

export const molUrlArr = [
  "AgPbBrO",
  "AlAs",
  "AlClO",
  "AsI3",
  "AuSe",
  "Ba(AgGe)2",
  "Ba(AgSn)2",
  "Ba(AlSi)2",
  "Ba(AsPd)2",
  "Ba(AsRu)2",
  "Ba(BIr)2",
  "Ba(BRh)2",
  "Ba(Cu2P)4",
  "Ba(Cu5P2)2",
  "Ba(CuAs)2",
  "Ba(CuO)2",
  "Ba(CuP2)2",
  "Ba(CuS)2",
  "Ba(CuSe)2",
  "Ba(CuTe)2",
  "Ba(Fe2O3)9",
  "Ba(FeAs)2",
  "Ba(FeO2)2",
  "Ba(FeP)2",
  "Ba(FeS2)2",
  "Ba(GeRh)2",
  "Ba(MgGe)2",
  "Ba(MgSi)2",
  "Ba(MnGe)2",
  "Ba(MnP)2",
  "Ba(MnSn)2",
  "Ba(Ni5P3)2",
  "Ba(NiAs)2",
  "Ba(NiO2)4",
  "Ba(NiP)2",
  "Ba(NiP2)2",
  "Ba(POs)2",
  "Ba(PRu)2",
  "Ba(SbPd)2",
  "Ba",
  "Ba2(CuO2)3",
  "Ba2BiSbO6",
  "Ba2Co9O14",
  "Ba2CoO4",
  "Ba2CoS3",
  "Ba2Cr7O14",
  "Ba2CrO4",
  "Ba2Cu2O5",
  "Ba2Cu3P4",
  "Ba2CuO3",
  "Ba2Fe2O5",
  "Ba2Fe6O11",
  "Ba2FeO4",
  "Ba2LaIrO6",
  "Ba2LaRuO6",
  "Ba2LaSbO6",
  "Ba2LaTaO6",
  "Ba2MnO3",
  "Ba2MnO4",
  "Ba2MnS3",
  "Ba2MnSe3",
  "Ba2MnTe3",
  "Ba2Na(CuO2)3",
  "Ba2Na(NiO2)3",
  "Ba2NdSbO6",
  "Ba2PrNbO6",
  "Ba2S3",
  "Ba2SrTeO6",
  "Ba2SrWO6",
  "Ba2TaBiO6",
  "Ba2Ti11O24",
  "Ba2Ti13O22",
  "Ba2Ti6O13",
  "Ba2Ti9O20",
  "Ba2TiO4",
  "Ba2TiS4",
  "Ba2V2O7",
  "Ba2V2ZnO8",
  "Ba2V3O9",
  "Ba2VO4",
  "Ba2ZnO3",
  "Ba2ZnS3",
  "Ba3(CrS3)2",
  "Ba3Co10O17",
  "Ba3Cr2O8",
  "Ba3CrO5",
  "Ba3CrS5",
  "Ba3Fe3O8",
  "Ba3Fe3Se7",
  "Ba3FeO5",
  "Ba3FeS5",
  "Ba3Mn2O8",
  "Ba3NiO4",
  "Ba3Ti20O40",
  "Ba3Ti2O7",
  "Ba3Ti3O8",
  "Ba3TiS5",
  "Ba3V2O8",
]
