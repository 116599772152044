import { useEffect, useState } from "react"
import { ViewerCanvas } from "./ViewerCanvas"
import { molUrlArr, BASEURL } from "@/config/molToJson"

const canvasStyle = {
  bonds_width_2D: 1.2,
  bonds_saturationWidthAbs_2D: 12.6,
  bonds_hashSpacing_2D: 12.5,
  atoms_font_size_2D: 25,
  atoms_font_families_2D: ["Helvetica", "Arial", "sans-serif"],
  atoms_displayTerminalCarbonLabels_2D: true,
  atoms_useJMOLColors: true,
  bonds_color: "white",
  // border:'0px',
  backgroundColor: "transparent",
}

const moleculeStyle = {
  scaleToAverageBondLength: 84.4,
}

function ViewerCanvasExample({ current, width, height, id, style, index }) {
  const [mol, setMol] = useState("")
  useEffect(() => {
    fetch(`${BASEURL}${molUrlArr[current]}.mol`)
      .then((data) => {
        return data.text()
      })
      .then((res) => {
        setMol(res)
      })
  }, [mol, current])

  return (
    <ViewerCanvas
      id={id}
      index={index}
      // current={current}
      data={mol}
      width={width}
      height={height}
      style={style}
      canvasStyle={canvasStyle}
      moleculeStyle={moleculeStyle}
    />
  )
}

export default ViewerCanvasExample
